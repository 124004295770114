import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { paginateArray, sortCompare } from "@/@fake-db/utils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BJumbotron } from "bootstrap-vue";
// import { is } from "core-js/core/object";

const getFirebaseData = async (selectedDate) => {
    var date = new Date(selectedDate).toLocaleDateString(
        "en-GB",
        {
          // you can use undefined as first argument
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );
  let studentsList = [];
  // console.log(store.state);
  var studentCode = store.state.mainUserCodes.StudentCode;
  const uid = firebase.auth().currentUser.uid;

  let result = await new Promise((resolve, reject) => {
    const interviewRef = firebase.firestore().collection("InterviewSchedule");
    
    
    let totalAppliedStudents = 0
    let parsedStudents = 0
    let recruiterCode = null;
    interviewRef.where("interviewFor", "==", uid)
    .where("fulldate", "==", date).get().then((interviews) => {
        totalAppliedStudents = interviews.size
        interviews.forEach((doc) => {
            // if(doc.data().StudentCode == studentCode)
            var student_id = doc.data().StudentId;
                      var obj = {};
                      obj.interviewid = doc.id;
                      obj.id = student_id;
                      obj.name = doc.data().FullName;
                      obj.contact_no = doc.data()["ContactNo"];
                      obj.time = doc.data().startTime;
                      obj.date = doc.data().fulldate;
                      obj.JobTitle = doc.data().JobTitle;
                      obj.interviewtimestamp = doc.data().startTimestamp;
                      obj.round = doc.data().whichRound;
                      obj.currentRound = doc.data().whichRound;
                      obj.schedulebutton = doc.data().isInterviewDone;
                      obj.profile = doc.data().profilephoto;
                      if (typeof doc.data().isHired !== "undefined") {
                        obj.isHired = doc.data().isHired;
                      } else {
                        obj.isHired = false;
                      }
                      if (
                        typeof doc.data()[`Round${obj.round}_status`] !==
                        "undefined"
                      ) {
                        obj.status = doc.data()[`Round${obj.round}_status`];
                      } else obj.status = "-";
                      if (
                        typeof doc.data()[`Round${obj.round}_remarks`] !==
                        "undefined"
                      ) {
                        obj.remarks = doc.data()[
                          `Round${obj.round}_remarks`
                        ];
                      } else obj.remarks = "";
                      obj.jobid = doc.data().jobid;
                      obj.RecruiterData = {
                        RecruiterId: uid,
                        RecruiterCode: doc.data().RecruiterCode,
                        CompanyName: doc.data().CompanyName,
                        JobTitle: doc.data().JobTitle,
                        logo: doc.data().logo
                      }
                    //   recruiterCode = doc.data().RecruiterCode
                      studentsList.push(obj)
                      parsedStudents = parsedStudents + 1
        })
    }).then(() => {
        if (totalAppliedStudents == parsedStudents) {
            // var finalObj = {
            //   jobsArray : jobsOptions,
            //   users: studentsList
            // }
            resolve(studentsList)
          }
    })


  });

  return result;
};

let users = [];
let filteredData = [];
let jobNameList = [];
let recCode = null;
const getDataandSort = async (config) => {
    const {
        q = "",
        perPage = 10,
        page = 1,
        sortBy = "id",
        sortDesc = false,
        selectedDate
        
      } = config;
  if (users.length < 1 || config.isValueUpdated == true) {
  
  users = await getFirebaseData(selectedDate);
    // users = receivedObj.users
    // jobNameList = receivedObj.jobsArray
    // recCode = receivedObj.recruiterCode
  }
  
  
  

  const queryLowered = q.toLowerCase();
  // const filteredData = users;
  // console.log(filteredData.length);

  let user = users.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      
      user.name.toLowerCase().includes(queryLowered) && 
      user.RecruiterData.JobTitle.toLowerCase().includes(queryLowered)
    
  );

  // console.log(user.length)
  const sortedData = user.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();
  filteredData = sortedData;
  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: sortedData.length,
      rawUsers: users,
    },
  ];
};


export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "name",
      label: "Student Name",
      formatter: title,
      sortable: true,
    },
    
    {
        key: "JobTitle",
        label: "Job Details",
        formatter: title,
        sortable: true,
      },
      {
        key: "currentRound",
        label: "Interview Round",
        formatter: title,
        sortable: true,
      },
      {
        key: "time",
        label: "Interview Time",
        formatter: title,
        sortable: true,
      },
    {
      key: "status",
      label: "Status",
      formatter: title,
      sortable: true,
    },

    // { key: "verified", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  
  const recruiterCodeFire = ref('');
  const rawList = ref([]);
  const filteredList = ref([]);
  const isValueUpdated = ref(false);
  const jobOptionsList = ref([])
  const getselectedDateTimestamp = ref(Date.now())

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = (val) => {
    console.log("refetching....");
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      isValueUpdated,
    //   getselectedDateTimestamp
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
   getDataandSort({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      isValueUpdated: isValueUpdated.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    selectedDate:getselectedDateTimestamp.value ?? ''
    })
      .then((response) => {
        // console.log(response);
        const { users, total, rawUsers } = response[1];
        // console.log(rawUsers);
        // console.log('here we have raw users');
        // console.log(users)
        
        rawList.value = rawUsers;
        filteredList.value = filteredData;
        callback(users);
        totalUsers.value = total;
        isValueUpdated.value = false;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  const resolveUserStatusVariant = (status) => {
    if (status == "Hired" || status == "Joined")
    return "success";
  else if (status == "Rejected" || status == "Resigned")
    return "danger";
  else if (status == "Reschedule" || status == "Working")
    return "warning";
  else if (status == "Did not Pick" || status == "OLSent")
    return "dark";
    else if (status == "--")
    return "secondary";
  else return "primary";
    // if (status === "pending") return "warning";
    // if (status === true) return "success";
    // if (status === false) return "secondary";
    // return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    isValueUpdated,
    jobOptionsList,
    recruiterCodeFire,
    getselectedDateTimestamp,

    resolveUserStatusVariant,
    refetchData,
    rawList,
    filteredList,

    // Extra Filters
    
  };
}