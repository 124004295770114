<template>
    <div>

        <!-- Filters -->
        <!-- <users-list-filters :round-filter.sync="roundFilter" :status-filter.sync="statusFilter" :job-filter.sync="jobFilter"
      :round-options="roundOptions" :status-options="statusOptions" :job-options="jobOptionsList" /> -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2 student-header-box">
                <!-- Table Top -->
                <b-row>
                    <!--select date and time -->
                    <b-col cols="12" md="4"
                        class="d-flex align-items-center align-middle justify-content-start mb-1 mb-md-0 interview-date-picker">
                        <label class="text-nowrap mr-50">Select Date</label>
                        <flat-pickr v-model="getselectedDateTimestamp" class="form-control"
                            @on-change="listenToOnChangeEvent"
                            :config="{ dateFormat: 'Y-m-d', altInput: true, altFormat: 'F j, Y', }" />
                    </b-col>
                    <!-- Per Page -->
                    <b-col cols="12" md="4"
                        class="d-flex align-items-center align-middle justify-content-center mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50" />
                        <label>entries</label>
                    </b-col>


                    <!-- Search -->
                    <b-col cols="12" md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                            <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              > -->
                            <!-- <b-button variant="primary" @click="DownloadCSV">
                <span class="text-nowrap">Download</span>
              </b-button> -->
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative students-interview-list-table" :items="fetchUsers"
                responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                empty-text="No Interviews scheduled for this day" :sort-desc.sync="isSortDirDesc">
                <!-- Column: User -->
                <template #cell(name)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar size="48" square variant="transparent" class="text-secondary"
                                :src="data.item.profile" :text="avatarText(data.item.name)" :to="{
                                    name: 'candidate-profile',
                                    params: { studentId: data.item.id, jobId : data.item.jobid },
                                }" />
                        </template>
                        <b-link :to="{
                            name: 'candidate-profile',
                            params: { studentId: data.item.id , jobId : data.item.jobid },
                        }" class=" d-block text-nowrap text-capitalize candidate-name" style="font-weight:600;color:#043c57">
                            {{ data.item.name }}
                        </b-link>
                        <small>{{ data.item.email }}</small>
                    </b-media>
                </template>



                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                        class="text-capitalize">
                        {{ data.item.status }}
                    </b-badge>
                </template>
                <template #cell(currentRound)="data">
                    <span class="text-nowrap text-center" v-if="data.item.currentRound != 0">Round {{
                            data.item.currentRound
                    }}</span>
                    <span class="text-nowrap text-center" v-else>--</span>

                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <div class="text-nowrap">
                        <b-button v-b-tooltip.hover title="Start Chat" v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="flat-dark" class="btn-icon" :to="{name:'apps-chat-recruiter',params:{studentId: data.item.id}}">
                            <u-icon icon="fi-rr-comment-alt" size="16" />
                        </b-button>
                        <b-button v-b-tooltip.hover title="Update Interview Feedback"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'" :id="`button-feedback-${data.item.id}`"
                            variant="flat-dark" class="btn-icon" :disabled="data.item.isHired"
                            @click="selectedModal(data.item)">
                            <u-icon icon="fi-rr-edit" size="16" />
                        </b-button>
                        <!-- v-b-modal="`modal-feedback-${data.item.id}`" -->
                        <b-modal :id="`modal-feedback-${data.item.id}`" ref="my-modal" centered :no-close-on-esc="true"
                            size="sm" :no-close-on-backdrop="true" title="Update Interview Feedback"
                            ok-title="Update Feedback" class="interview-modal" cancel-variant="outline-secondary"
                            :hide-header-close="true" @show="resetModal" @hidden="resetModal"
                            @ok="(event) => handleOk(event, data.item)">
                            <validation-observer :ref="`updateFeedbackForm-${data.item.id}`" #default="{ invalid }">

                                <form @submit.stop.prevent="handleSubmit(data.item)">
                                    <validation-provider #default="{ errors }" name="status" rules="required">
                                        <b-form-group :state="errors.length > 0 ? false : null">

                                            <label> Select Status<label class="text-danger">*</label></label>
                                            <v-select v-model="status"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :state="errors.length > 0 ? false : null" :options="statusOptions"
                                                name="status" :reduce="val => val.value" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>

                                    </validation-provider>
                                    <b-form-group>
                                        <!-- <validation-provider #default="{ errors }" name="remarks"> -->

                                        <label class="mb-50"> Add Remarks</label>
                                        <b-form-textarea v-model="remarks" rows="4"
                                            placeholder="Add your remarks here..." name="remarks" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->

                                        <!-- </validation-provider> -->
                                    </b-form-group>
                                </form>
                            </validation-observer>
                        </b-modal>

                    </div>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, VBTooltip, BModal, BFormGroup, VBModal, BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
// import UsersListFilters from "./UsersListFilters.vue";
import useInterviewList from "./useInterviewList";
import firebase from "firebase/app";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        // UsersListFilters,
        BTooltip,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormGroup,
        ValidationObserver,
        ValidationProvider,
        BFormTextarea,

        vSelect,
        flatPickr,
        ToastificationContent
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
        'b-modal': VBModal,

    },
    data() {
        return {
            selectedDate: null,
            required,
            status: null,
            remarks: null,
            statusOptions: [
                { label: "Did not Pick", value: "Did not Pick" },
                { label: "Reschedule", value: "Reschedule" },
                { label: "Rejected", value: "Rejected" },
                { label: "Shortlisted", value: "Shortlisted" },
                { label: "Hired", value: "Hired" },
            ],

        }
    },

    created() {
        //changing when store value changed
        this.$store.watch(
            (state) => {
                return this.$store.state.mainUserCodes;
            },
            (newVal, oldVal) => {
                this.fetchUsers;
            },
            { deep: true }
        );
    },
    setup() {
        const isAddNewUserSidebarActive = ref(false);


        const {
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            isValueUpdated,
            refUserListTable,
            refetchData,
            recruiterCodeFire,
            getselectedDateTimestamp,
            // UI

            resolveUserStatusVariant,
            rawList,
            filteredList,

            // Extra Filters

        } = useInterviewList();


        function arraytocsv(data) {
            const csvString = [
                [
                    "Name",
                    "email",
                    "Course",
                    "Batch",
                    "Jobs Applied",
                    "Status",
                    "Verified",
                ],
                ...data.map((item) => [
                    `"${item.FullName}"`,
                    `"${item.email}"`,
                    `"${item.courseName}"`,
                    `"${item.batch}"`,
                    `"${item.appliedIn}"`,
                    `"${item.placed}"`,
                    `"${item.verified}"`,
                ]),
            ]
                .map((e) => e.join(","))
                .join("\n");

            console.log(csvString);
            return csvString;
            //   return data.map(row =>{

            //     console.log(row)
            //     row
            //     // .map(String)  // convert every value to String
            //     .map(v => v.replaceAll('"', '""'))  // escape double colons
            //     .map(v => `"${v}"`)  // quote it
            //     .join(',')  // comma-separated
            //  } ).join('\r\n')
        }

        function downloadBlob(content, filename, contentType) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement("a");
            pom.href = url;
            pom.setAttribute("download", filename);
            pom.click();
        }
        function DownloadCSV() {
            let data = arraytocsv(filteredList.value);

            downloadBlob(data, "Students.csv", "text/csv;charset=utf-8;");
        }


        return {
            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            isValueUpdated,
            getselectedDateTimestamp,
            // Filter
            avatarText,
            // UI
            resolveUserStatusVariant,

            recruiterCodeFire,

            // Extra Filters

            DownloadCSV,
        };
    },

    methods: {
        selectedModal(data) {

            if (!data.isHired) {

                this.$bvModal.show(`modal-feedback-${data.id}`)
            } else {
                console.log('dont trigger')
                // console.log(this.$bvModal)
                // this.$bvModal[]
                // this.$root.$emit('bv::toggle::modal', `modal-feedback-${data.id}`, `button-feedback${data.id}`)

            }
        },

        resetModal() {
            this.status = null
            this.remarks = null
        },
        listenToOnChangeEvent(selectedDates, dateStr, instance) {
            // this.selectedDate = selectedDates;
            console.log(selectedDates)
            this.isValueUpdated = true
        },
        getDifferentDates() {
            let startdate = new Date(this.selectedDate);
            let fulldate = new Date(this.selectedDate).toLocaleDateString("en-GB", {
                // you can use undefined as first argument
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            let stime = startdate.getTime();
            let st = new Date(stime).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });
            var obj = {
                fulldate: fulldate,
                startTimestamp: startdate,
                starttime: st,
            };
            return obj;
        },
        handleOk(bvModalEvt, data) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit(data)
        },
        updateFeedback(tr) {
            var user = firebase.auth().currentUser;
            var obj = {};
            obj[`Round${tr.currentRound}_interviewID`] = tr.interviewid;
            obj[`Round${tr.currentRound}_status`] = this.status;
            obj[`Round${tr.currentRound}_remarks`] = this.remarks;
            obj[`Round${tr.currentRound}_hrID`] = user.uid;
            obj[`isRound${tr.currentRound}Done`] = true;
            obj[
                `Round${tr.currentRound}_date`
            ] = firebase.firestore.FieldValue.serverTimestamp();
            // var date = new Date(tr.date);
            var dateParts = tr.date.split("/");

            // month is 0-based, that's why we need dataParts[1] - 1
            var date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            
            obj[
                `Round${tr.currentRound}_scheduledate`
            ] = firebase.firestore.Timestamp.fromDate(date);
            obj.currentRound = tr.currentRound;
            obj.isInterviewDone = true;
            if (this.status == "Hired") {
                obj.isHired = true;
            } else {
                obj.isHired = false;
            }
            if (this.status == "Rejected") {
                obj.isRejected = true;
            } else {
                obj.isRejected = false;
            }
            // console.log(obj)
            var Ref = firebase
                .firestore()
                .collection("InterviewSchedule")
                .doc(tr.interviewid);
            Ref.set(obj, { merge: true })
                .then(() => {
                    console.log("saved in interview");
                })
                .catch((err) => {
                    console.log("error  in interview schedule");
                    console.log(err);
                });
            var db = firebase.firestore().collection("StudentID").doc(tr.id);
            db.get()
                .then((doc) => {
                    if (doc.exists) {
                        db.collection("InterviewRemarks")
                            .doc(tr.jobid)
                            .set(obj, { merge: true })
                            .then(() => {
                                console.log("saved in student");
                            });
                    }
                })
                .then(() => {
                    firebase
                        .firestore()
                        .collection("RecruiterJobs")
                        .doc(tr.jobid).update({
                            TotalInterviewed : firebase.firestore.FieldValue.increment(1)
                        })
                    var ref = firebase
                        .firestore()
                        .collection("RecruiterJobs")
                        .doc(tr.jobid)
                        .collection("AppliedCandidates")
                        .doc(tr.id);
                    ref.get().then((doc) => {
                        if (doc.exists) {
                            ref.update(obj).then(() => {
                                console.log("saved in job");
                            });
                        }
                    });
                })
                .then(() => {
                    // this.Interviewactive = false;
                    this.isValueUpdated = true;
                    this.$bvModal.hide(`modal-feedback-${tr.id}`)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Feedback Updated",
                            icon: "AlertTriangleIcon",
                            variant: "success",
                            text: `Successfully updated the feedback of ${tr.name}`,

                        },
                    });

                });
        },
        handleSubmit(data) {
            // Exit when the form isn't valid

            this.$refs[`updateFeedbackForm-${data.id}`].validate().then(async success => {
                console.log(`checking success ${success}`)
                if (success) {
                    // this.updateFeedback(data)
                    console.log(this.status)
                    if (this.status == "Rejected") {
                        this.$bvModal
                            .msgBoxConfirm('Are you sure, you want to reject this candidate?', {
                                title: 'Confirm Rejection',
                                size: 'sm',
                                okVariant: 'danger',
                                okTitle: 'Confirm',
                                cancelTitle: 'Cancel',
                                cancelVariant: 'outline-secondary',
                                hideHeaderClose: true,
                                centered: true,
                                buttonSize: 'sm'

                            })
                            .then(value => {
                                console.log(`this is value of rejected ${value}`)
                                if (value) {
                                    this.updateFeedback(data)
                                }
                            })

                    } else if (this.status == "Hired") {
                        this.$bvModal
                            .msgBoxConfirm(' Are you sure, you want to hire this candidate?', {
                                title: 'Confirm Hiring',
                                size: 'sm',
                                okVariant: 'success',
                                okTitle: 'Confirm',
                                cancelTitle: 'Cancel',
                                cancelVariant: 'outline-secondary',
                                hideHeaderClose: true,
                                centered: true,
                                buttonSize: 'sm'

                            })
                            .then(value => {
                                console.log(`this is value of hiring ${value}`)
                                if (value) {
                                    this.updateFeedback(data)
                                }
                            })
                    } else {
                        this.updateFeedback(data)
                    }
                } else {
                    return
                }
            })

        },
    },
};
</script>

<style lang="scss" scoped>
.interview-modal {
    .modal-backdrop {
        background-color: rgba(4, 60, 87, 0.2)
    }
}
.candidate-name{
  &:hover {
    color: #039be5 !important;
          transition: all 0.25s ease;

    }
}
.per-page-selector {
    width: 90px;
    //height:28px;
    font-size: 14px;
    border-radius: 8px;
}

.vs__dropdown-toggle {
    border-radius: 8px;

}

.student-header-box {
    .interview-date-picker ::v-deep .form-control {
        height: 38px;
        border-radius: 12px;
        background-color: #fff;
    }

    .form-control {
        height: 38px;
        border-radius: 12px;
    }

}

.students-interview-list-table ::v-deep td:nth-child(3) {
    text-align: center;
}

.students-interview-list-table ::v-deep td:nth-child(4) {
    text-align: center;
}

.students-interview-list-table ::v-deep td:nth-child(5) {
    text-align: center;
}

.students-interview-list-table ::v-deep td:nth-child(6) {
    text-align: center;
}

.students-interview-list-table ::v-deep th:nth-child(4) {
    text-align: center;
}

.students-interview-list-table ::v-deep th:nth-child(5) {
    text-align: center;
}

.students-interview-list-table ::v-deep th:nth-child(6) {
    text-align: center;
}

.students-interview-list-table ::v-deep thead {
    tr:first-child {
        th {
            border-top: 1px solid #efefef;
        }
    }

    tr:first-child {
        th:first-child {
            border-top-left-radius: 0px;
        }

        th:last-child {
            border-top-right-radius: 0px;
        }
    }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
